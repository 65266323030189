.imgOnlineMarketingParent {
  overflow-x: hidden;
  position: relative;
  text-align: center;
  color: white;
}

.imgheaderVacature {
  width: 100vw;
  height: 800px;
  object-fit: cover;
  object-position: 70% center;
  overflow: hidden;
  position: relative;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);
}

.pageIntroTitle {
  font-size: 80px;
  display: flex;
  white-space: wrap;
  width: 100%;
  max-width: 100vw;
  justify-content: center;
}

.pageIntroSubtitle {
  font-size: 30px;
}

.dividers {
  font-size: 23px;
  color: rgba(255, 255, 255, 0.63);
  margin: 0px 10px;
}

.offerAndExploreParent {
  position: relative;
  bottom: 150px;
}

.offerAndExplore {
  background-color: white;
  width: 85vw;
  height: fit-content !important;
  min-height: 500px;
  height: 500px;
  padding: 75px 100px;
}

.weOffer,
.exploreMore {
  width: 50%;
}

.offerAndExploreInfo {
  width: 50%;
  flex-direction: row !important;
}

.knowMore {
  width: 50%;
}

.indicator {
  margin-left: 10px;
  font-size: 20px;
}

.accordion-content {
  max-height: 0;
  overflow-y: scroll;
  transition: max-height 0.3s ease-out;
}

.accordion-content.open {
  max-height: 500px;
  /* Adjust based on content height */
  transition: max-height 0.3s ease-in;
}

.urJob {
  position: relative;
  bottom: 75px;
}

.urJobInfo,
.imgUrJob {
  width: 50%;
}

.imgVideoDayAs {
  max-width: 700px;
  height: auto;
  align-self: center;
}

.imgVideoDayAs.horizontal {
  max-width: 700px;
  height: 880px;
  object-fit: contain;
  display: flex;
  align-self: flex-start;
}

.imgUrJob {
  height: 400px;
  width: auto;
  max-width: 900px;
  max-height: 518px;
}

.urJobInfo {
  height: 518px;
  overflow-y: scroll;
  padding-right: 140px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.urFuncParent,
.procentageJob {
  width: 50%;
}

.urFuncParent {
  display: flex;
  justify-content: center;
}

.urFunc {
  font-family: "proxima-nova", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 157px;
  width: 661px;
  /* word-spacing: 10px; */
  line-height: 197px;
}

.urFuncProcentage {
  display: flex;
  flex-direction: row;
  padding: 0px 40px;
  position: relative;
  top: 100px;
  top: 100px;
}

.ProcentageCircle {
  background-color: rgb(88, 88, 255);
  color: white;
  width: 150px;
  border-radius: 100%;
  height: 150px;
  padding: 30px;
  font-size: 45px;
  min-width: 150px;
  min-height: 150px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dayAsVideoPlaceholder {
  height: 860px;
  object-fit: contain;
}

.dayAs {
  background-color: #2283c5;
  height: 740px;
  padding: 90px 140px;
  position: relative;
  top: 200px;
}

.textDayAs {
  color: white;
  font-size: 80px;
  font-weight: bold;
  text-align: left;
  width: 51%;
  align-self: center;
  margin-left: 50px;
}

.questionsContact {
  position: relative;
  /* top: 635px; */
  top: 250px;
  display: flex;
  align-items: center;
}

.questionsContactText {
  padding-left: 138px;
  padding-right: 38px;
}

.titleTextQuestionsContact {
  font-size: 35px;
  font-weight: bold;
}

.subTextQuestionsContact {
  font-size: 18px;
}

.questionsContactImg {
  height: 350px;
  width: auto;
  max-width: 100%;
  max-height: 900px;
}

.questionsContactImgParent {
  display: flex;
  justify-content: end;
  /* max-width: 50%; */
  align-items: center;
}

.questionsContact>div:first-child>div:last-child>button:first-child {
  margin-right: 1rem;
}

.questionsContact>div:first-child>div:last-child>button:last-child>div {
  height: 40px;
  display: flex;
  align-self: center;
}

.ourColleagues {
  position: relative;
  /* top: 817px; */
  top: 417px;
  display: flex;
  flex-direction: row;
}

.ourColleaguesImg {
  width: 50%;
  /* height: 700px; */
  overflow: hidden;
  /* Verbergt delen van de afbeelding die buiten het kader vallen */
}

.ourColleaguesImg>img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Zorgt ervoor dat de afbeelding proportioneel blijft en de hele container vult */
}

.ourColleaguesTitle {
  font-size: 35px;
}


.textColleagueOne {
  font-size: 20px;
}

.textColleague {
  font-size: 18px;
  overflow-x: hidden;
}

.weOfferItemsScroll {
  margin-right: 20px;
}


.ourColleaguesWords {
  background-color: #f8f8f8;
  padding: 90px 70px;
  width: 50%;
}

.wordbreakAll {
  word-break: break-all;
}

.whiteSpaceWrap {
  white-space: normal;
}

.ourClients {
  position: relative;
  /* top: 976px; */
  top: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.ourClientsText {
  padding-left: 140px;
  width: 40%;
  margin-right: 50px;
}

.titleTextOurClients {
  font-size: 35px;
  font-weight: bold;
  margin-bottom: 3rem;
}

.subTextOurClients {
  font-size: 22px;
  margin-bottom: 3rem;
}

.ourClientsImgParent {
  display: flex;
  align-items: center;
  height: 756px;
  width: auto;
  overflow: hidden;
  /* Ensure the overflow is hidden to crop the image */
}

.ourClientsImg {
  max-width: 100%;
  max-height: 756px;
  width: auto;
  height: 400px;
  object-fit: contain;
  /* Scale the image to cover the container */
  object-position: top;
  /* Align the image to the top, cropping from the bottom */
}


.ourClients>div:first-child>div:last-child>button:first-child {
  margin-right: 1rem;
}

.ourClients>div:first-child>div:last-child>button:last-child>div {
  display: flex;
  align-self: center;
}

.bulletItem {
  display: flex;
  align-items: start;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}

.bulletItem>p {
  flex-wrap: wrap;
}

.checkBackground {
  background-color: #2283c5;
  height: 40px;
  min-width: 40px;
  padding: 10px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 40px;
  overflow: hidden;
}

.checkImg {
  height: 100%;
  /* Ensures the image height matches the container */
  width: auto;
  /* Maintains the aspect ratio */
}

.aboutUs {
  background-color: #2283c5;
  position: relative;
  /* top: 1095px; */
  top: 600px;
  padding: 135px 140px;
  max-height: 1000px;
  color: white;
}

.aboutUsText {
  font-size: 18px;
}

.aboutUsSubtitle {
  font-size: 22px;
}

.discoverUs>button>p {
  margin: 0px 30px !important;
}

.imageAboutUsParent {
  display: flex;
  justify-content: center;
}

.imageAboutUs {
  height: auto;
  width: 100%;
  max-width: 1920px;
}

.smallFactsCompany {
  position: relative;
  /* top: 1700px; */
  top: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0px 140px;
}

.companyInfoCircle {
  /* background-color: rgb(88, 88, 255); */
  color: white;
  width: 170px;
  border-radius: 100%;
  height: 170px;
  padding: 30px;
  font-size: 45px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactForm {
  position: relative;
  /* top: 1760px; */
  top: 1400px;
  background-color: #f8f8f8;
  padding: 103px 140px;
}

.ContactFormInputs {
  width: 60% !important;
}

.contactInputs {
  width: 100%;
  background-color: white;
  border: none;
  padding: 10px;
  padding-left: 30px;
  border-radius: 30px;
  margin-bottom: 2rem;
}

.contactInputs::placeholder {
  font-size: large;
  font-weight: 600;
}

.submitButtonContact {
  background-color: #2283c5;
  border-radius: 30px;
  color: white;
  border: none;
  padding: 10px;
  width: 100%;
  font-size: 17px;
  font-weight: 600;
}

.reactionText {
  font-style: italic;
  font-size: 18px;
  font-weight: 600;
}

.weOfferWordbreak {
  word-wrap: break-word;
  padding-right: 20px;
  font-size: 18px;
}

.exploreMoreWordBreak {
  word-wrap: break-word;
  padding-right: 20px;
  font-size: 18px;
}

.jobdesc {
  white-space: normal;
  font-size: 18px;
}

.mailButton>a>div {
  height: 37px;
  width: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textsColleagues {
  margin-bottom: 20px;
}

.footerHomepage {
  position: relative;
  top: 1150px;
}

@media (min-width: 1921px) {
  .urJob {
    justify-content: space-between;
  }

  .imgOnlineMarketing {
    height: 1300px !important;
    object-fit: fill !important;
    object-position: top;
    overflow: visible !important;
    position: relative;
  }
}

@media (max-width: 1990px) {
  .smallFactsCompany {
    position: relative;
    top: 1150px;
  }

  .contactForm {
    position: relative;
    top: 1200px;
  }
}

@media (max-width: 1560px) {
  .questionsContactText {
    padding: 0px 50px;
  }

  .questionsContactImg {
    max-height: 600px;
  }

  .ourColleaguesTitle {
    font-size: 40px;
  }

  .titleTextOurClients {
    font-size: 35px;
    text-align: center !important;
  }

  .smallFactsCompany {
    position: relative;
    top: 1000px;
  }

  .contactForm {
    position: relative;
    top: 1100px;
  }

  .textColleague {
    font-size: 16px;
  }

  .nameEmployee {
    font-size: 18px !important;
  }

  .dayAsVideoPlaceholder {
    height: 700px;
  }

  .ourClientsText {
    padding-left: 75px;
  }

  .urFunc {
    font-size: 100px;
  }

  .footerHomepage {
    top: 1030px;
  }
}

@media (max-width: 1440px) {
  .jobDescriptionHomepage {
    padding: 10px 20px;
  }

  .questionsContact {
    top: 40px;
  }

  .ourColleagues {
    top: 120px;
  }

  .aboutUs {
    top: 250px;
  }
  .textDayAs {
    font-size: 60px;
  }

  .ourColleaguesTitle {
    font-size: 30px;
  }

  .textsColleagues>div>p {
    font-size: 20px;
  }

  .ourClients {
    flex-direction: column !important;
    top: 175px;
  }

  .ourClientsText {
    width: auto !important;
  }

  .subTextOurClients,
  .titleTextQuestionsContact {
    text-align: center !important;
  }

  .ourClientsTextList {
    display: flex;
    flex-direction: column;
    align-items: left;
    padding-left: 20%;
  }

  .bulletItem {
    align-items: start !important;
  }

  .aboutUsText {
    font-size: 20px;
  }

  .smallFactsCompany {
    position: relative;
    top: 500px;
  }

  .contactForm {
    position: relative;
    top: 580px;
    padding: 70px 75px;
  }

  .urFuncProcentage {
    top: 0px;
  }

  .ourClientsImgParent {
    height: fit-content;
  }
  .ourClientsImg {
    height: 450px;
  }

  .ourClientsTextList>div {
    margin: 0px !important;
  }

  .footerHomepage {
    top: 550px;
  }
}

@media (max-width: 1345px) {

  .urFuncProcentage {
    top: 0px;
  }

  .questionsContact {
    top: 50px;
  }

  .ourColleagues {
    top: 175px;
  }

  .ourClients {
    top: 250px;
  }

  .aboutUs {
    top: 350px;
  }

  .offerAndExplore {
    flex-direction: column-reverse !important;
    height: fit-content !important;
  }

  /* .weOffer,
  .exploreMore {
    text-align: center !important;
  } */

  .offerAndExploreInfo,
  .knowMore {
    width: 100%;
  }

  .knowMore {
    margin-bottom: 40px;
  }

  .imgUrJob {
    object-fit: contain;
    width: 500px;
    height: auto;
    max-width: 100vw;
    max-height: 400px;
  }

  .urJobInfo {
    height: 450px;
  }

  .textDayAs {
    font-size: 50px;
  }

  /* .imgVideoDayAs {
    height: 600px;
  } */

  .smallFactsCompany {
    position: relative;
    top: 650px;
  }

  .contactForm {
    position: relative;
    top: 800px;
  }

  .footerHomepage {
    top: 780px;
  }
}

@media (max-width: 1276px) {

  .ourClients {
    top: 340px;
  }

  .aboutUs {
    top: 425px;
  }

  .smallFactsCompany {
    top: 650px;
  }

  .urFuncProcentage {
    flex-direction: column;
    top: 0px;
  }

  .dayAsVideoPlaceholder {
    height: 500px;
    object-fit: contain;
  }

  .urFunc {
    width: 100%;
    font-size: 60px;
    margin-bottom: 40px !important;
    text-align: center;
  }

  .urFuncParent {
    width: 100%;
  }

  .procentageJob {
    width: 100%;
    padding: 0px 50px;
  }

  .procentageJob>div {
    display: flex;
    flex-direction: row;
  }

  .procentageJob>div>div {
    width: 50%;
  }

  .questionsContact {
    top: 80px;
  }

  .titleTextQuestionsContact {
    font-size: 40px;
  }

  .subTextQuestionsContact {
    font-size: 15px;
  }

  .questionsContactImg {
    max-height: 325px;
  }

  .textsColleagues>div>p {
    font-size: 18px;
  }

  .textsColleagues>div>h4 {
    font-size: 20px;
  }

  .ourColleagues {
    top: 240px;
  }

  .ourColleaguesWords {
    padding: 40px 70px;
  }

  .dayAs {
    padding: 90px 120px;
  }

  .contactForm {
    top: 800px;
  }
}

@media (max-width: 1100px) {


  .contactForm {
    top: 350px;
  }

  .questionsContact {
    top: 50px;
  }

  .pageIntroTitle {
    font-size: 75px;
  }

  .urFuncProcentage {
    top: 45px;
  }

  .urFunc {
    line-height: 70px;
    font-size: 50px;
  }

  .pageIntroSubtitle {
    font-size: 25px;
  }

  .dividers {
    font-size: 19px;
  }

  .dayAs {
    flex-direction: column-reverse !important;
    height: fit-content !important;
    align-items: end;
    padding: 30px 50px;
  }

  .imgVideoDayAs {
    align-self: center !important;
  }

  .imgVideoDayAs.horizontal {
    height: 500px;
  }

  .textDayAs {
    width: 100%;
    margin-bottom: 20px;
    margin-left: 0px;
  }

  .questionsContactImg {
    margin-bottom: 20px;
  }

  .urJob {
    flex-direction: column !important;
    bottom: 0px;
  }

  .urJobInfo {
    width: auto;
    height: fit-content;
    overflow-y: hidden;
  }

  .imgUrJob {
    width: 100%;
    max-height: 54.4vw;
    align-self: center;
  }

  .offerAndExploreParent {
    position: relative;
    bottom: 50px;
  }

  .ourClients {
    top: 175px;
  }

  .ourClientsImg {
    height: 400px;
  }

  .questionsContact {
    flex-direction: column-reverse !important;
  }

  .questionsContactImgParent {
    width: 100% !important;
    justify-content: center;
  }

  .questionsContactText {
    width: 100% !important;
  }

  .questionsContactImg {
    max-height: 55vw;
    max-width: fit-content;
    width: 100% !important;
  }

  .ourColleagues {
    display: flex;
    flex-direction: column;
    top: 120px;
  }

  .ourColleaguesImg {
    width: 100%;
    height: 600px;
  }

  .ourColleaguesWords {
    width: 100%;
  }

  .procentageJob {
    width: 100%;
  }

  .procentageJob>div {
    display: flex;
    flex-direction: row;
  }

  .procentageJob>div>div {
    width: 100%;
    align-items: center;
    flex-direction: column !important;
  }

  .procentageJob>div>div>p {
    padding-right: 0px !important;
    text-align: center;
  }

  .ProcentageCircle {
    margin-right: 0px !important;
  }

  .imgheaderVacature {
    height: 600px !important;
  }

  .aboutUs {
    top: 240px;
    padding: 40px 80px;
  }

  .smallFactsCompany {
    position: relative;
    top: 300px;
    padding: 0px 0px;
  }

  .footerHomepage {
    top: 300px;
  }
}

@media (max-width: 890px) {

  .jobDescriptionHomepage {
    top: 0px;
  }

  .urFuncProcentage {
    top: 35px;
  }

  .urFunc {
    font-size: 40px;
  }

  .funcSubTitle {
    font-size: 25px !important;
  }

  .ourColleagues {
    top: 80px;
  }

  .pageIntroTitle {
    line-height: 50px;
    font-size: 55px;
  }

  .dayAsVideoPlaceholder {
    height: 360px;
    object-fit: contain;
  }

  .pageIntroSubtitle {
    font-size: 20px;
  }

  .dividers {
    font-size: 17px;
  }

  .centered {
    transform: translate(-50%, -100%);
  }

  .textDayAs {
    font-size: 40px;
  }

  /* .imgVideoDayAs {
    height: 400px;
  } */

  .questionsContact {
    position: relative;
    top: 25px;
  }

  .titleTextQuestionsContact {
    font-size: 35px;
  }

  .ourClients {
    top: 120px;
  }

  .ourClientsImgParent {
    height: auto;
  }

  .aboutUs {
    top: 180px;
    padding: 50px 80px;
  }

  .aboutUsText {
    font-size: 15px;
  }

  .smallFactsCompany {
    position: relative;
    top: 225px;
    padding: 0px 0px;
  }

  .smallFactsCompany>div>h2 {
    font-size: 20px;
  }

  .companyInfoCircle {
    width: 100px;
    height: 100px;
  }

  .companyInfoCircle>img {
    height: 50px !important;
  }

  .contactForm {
    position: relative;
    top: 250px;
  }

  .imgheaderVacature {
    height: 500px !important;
  }

  .ContactFormInputs {
    width: 100% !important;
  }
  .footerHomepage {
    top: 225px;
  }
}

@media (max-width: 800px) {

  .questionsContact {
    top: 50px;
  }

  .ourColleagues {
    top: 100px;
  }

  .ourClients {
    top: 125px;
  }

  .pageIntroTitle {
    white-space: wrap;
  }

  .offerAndExplore {
    padding: 25px 50px;
  }

  .procentageJob {
    width: 100%;
    padding: 0px 0px;
  }

  .procentageJob>div {
    display: flex;
    flex-direction: row;
  }

  .ourClientsImg {
    height: 250px;
  }

  .procentageJob>div>div {
    width: 100%;
    align-items: center;
    flex-direction: column !important;
  }

  .urFuncProcentage {
    top: 60px !important;
  }

  .ProcentageCircle {
    margin-right: 0px !important;
    margin-bottom: 30px;
    min-width: 100px;
    min-height: 100px;
    height: 100px;
    width: 100px;
    font-size: 30px;
  }

  .textColleague {
    overflow-y: visible;
    max-height: fit-content;
  }

  .urFunc {
    font-size: 45px;
  }

  .funcSubTitle {
    font-size: 20px !important;
  }

  .procentageJob>div>div>div>p {
    padding: 0px 10px !important;
  }

  .dayAs {
    flex-direction: column-reverse !important;
    height: fit-content !important;
    align-items: center;
    padding: 30px 50px;
  }

  .imgVideoDayAs {
    /* height: 500px; */
    width: auto;
  }

  .textDayAs {
    width: 100%;
    text-align: center;
    margin-bottom: 40px;
  }

  .ourClientsText {
    width: 80%;
    padding: 0px 0px;
    margin-right: 0px;
    margin-bottom: 0px;
  }

  .ourClientsTextList {
    margin-bottom: 0px !important;
  }

  .titleTextOurClients {
    margin: 0px 10px;
  }

  .smallFactsCompany {
    position: relative;
    top: 200px;
  }

  .contactForm {
    flex-direction: column !important;
    top: 220px;
  }

  .contactForm>div:first-child {
    width: 100% !important;
    margin-bottom: 40px;
  }

  .aboutUs {
    top: 150px;
    padding: 40px 30px;
  }

  .imgheaderVacature {
    height: 500px !important;
  }

  .footerHomepage {
    top: 150px;
  }

}


@media (max-width: 650px) {
  .ourClientsText {
    padding: 10px !important;
  }

  .ourClientsTextList {
    display: flex;
    flex-direction: column;
    padding-left: 10% !important;
  }

  .ourClientsTextList>div {
    margin: 0px !important;
  }


}

@media (max-width: 600px) {

  .jobDescriptionHomepage {
    padding-top: 30px;
  }

  
  .centered {
    transform: translate(-50%, -70%) !important;
  }

  .urFuncProcentage {
    top: 40px !important;
  }

  .ourColleagues {
    top: 100px;
  }

  .aboutUs {
    top: 180px;
  }

  .ourClientsImg {
    height: 200px;
  }

  .pageIntroTitle {
    white-space: wrap;
    font-size: 45px;
  }

  .weOfferWordbreak {
    padding-right: 0px;
  }

  .exploreMoreWordBreak {
    padding-right: 0px;
  }

  .textContactFormInfo>h2 {
    word-break: break-all;
  }

  .textContactFormInfo>p>p {
    word-break: break-all;
  }

  .knowMore>div:first-child>p {
    font-size: 30px !important;
  }

  .offerAndExplore {
    height: auto;
    min-height: 0;
    padding: 25px;
  }

  .offerAndExploreInfo {
    flex-direction: column !important;
  }

  .questionsContactText>div:nth-child(2)>div:nth-child(1) {
    margin-bottom: 10px;
  }

  .weOffer,
  .exploreMore {
    width: 100%;
    text-align: center !important;
  }

  .weOffer h3,
  .exploreMore h3 {
    margin-bottom: 30px !important;
    cursor: pointer;
  }

  .indicator {
    font-size: 1.5rem;
    margin-left: 10px;
  }

  .procentageJob>div>div>h2 {
    text-align: center;
  }

  .urFunc {
    font-size: 40px;
  }


  .pageIntroSubtitle {
    font-size: 12px;
  }

  .dividers {
    font-size: 11px;
  }

  .questionsContact {
    top: 30px;
  }

  .questionsContactText>div:nth-child(2) {
    flex-direction: column !important;
  }

  .questionsContactText>div:nth-child(2)>button:first-child {
    margin-right: 0px !important;
    margin-bottom: 10px;
  }

  .questionsContactText>div:nth-child(2)>button {
    width: fit-content;
  }

  .ourClients {
    top: 125px;
  }

  .ourClientsText {
    width: 100%;
    padding: 0px 0px 0px 50px;
    margin-bottom: 10px;
  }

  .ourClients>div:first-child>div:last-child>button:first-child {
    margin-right: 0rem;
  }

  .smallFactsCompany {
    position: relative;
    /* top: 650px; */
    top: 250px;
    flex-direction: column;
  }

  .smallFactsCompany>div {
    width: 100% !important;
  }

  .contactForm {
    position: relative;
    /* top: 680px; */
    top: 300px;
  }

  .imgVideoDayAs {
    max-width: 80vw;
  }

  .procentageJob>div>div>p {
    font-size: 18px !important;
  }

  .imgheaderVacature {
    height: 300px !important;
  }

  .footerHomepage {
    top: 250px;
  }
}

@media (max-width: 500px) {
  .smallFactsCompany {
    position: relative;
    /* top: 750px; */
    top: 175px;
    flex-direction: column;
  }

  .smallFactsCompany>div {
    width: 100% !important;
  }

  .contactForm {
    top: 180px;
  }

  .urFuncProcentage {
    top: 30px !important;
  }

  .ourColleagues {
    top: 80px;
  }

  .ourClients {
    top: 110px;
  }

  .aboutUs {
    top: 125px !important;
  }

  .questionsContact {
    top: 20px;
  }

  .footerHomepage {
    top: 150px;
  }
}

@media (max-width: 480px) {
  .centered {
    top: 50% !important;
  }

  .textDayAs {
    font-size: 35px;
  }

  .iconButtonArrowRight>button>span {
    font-size: 13px !important;
  }

  /* .imgVideoDayAs {
    height: 375px;
  } */


  .questionsContactText {
    padding: 0px 30px;
  }

  .titleTextQuestionsContact {
    font-size: 25px;
  }

  .questionsContactText>div:nth-child(2)>button {
    justify-content: center;
    display: flex;
  }

  .ourColleaguesImg {
    width: 100%;
    height: auto;
  }

  .ourColleaguesWords {
    padding: 40px 20px;
    text-align: left;
  }

  .iconButtonArrowRight {
    display: flex;
    justify-content: left;
  }

  .knowMore>div:first-child>p {
    font-size: 25px !important;
  }

  .urFuncProcentage {
    padding: 0px 15px;
  }

  .urFunc {
    font-size: 35px;
  }

  .aboutUsText {
    font-size: 12px;
  }

  .aboutUs {
    max-height: 1550px;
  }

  .contactForm {
    padding: 40px 30px;
  }

  .contactInputs::placeholder {
    font-size: 15px;
  }

  .ContactFormInputs>div>h1 {
    font-size: 25px !important;
  }

  .ContactFormInputs>div>p {
    font-size: 20px !important;
  }

  .reactionText {
    font-size: 15px;
  }

.ourClients {
  top: 70px;
}

.ourColleagues {
  top: 50px;
}
  .smallFactsCompany {
    position: relative;
    /* top: 600px; */
    top: 170px;
    flex-direction: column;
  }

  .imgheaderVacature {
    height: 400px;
  }

  .urJobInfo {
    padding: 15px 0px !important;
  }

  .ourClientsImg {
    height: 150px;
  }

  .titleTextOurClients {
    font-size: 30px;
  }

  .subTextOurClients {
    font-size: 20px;
  }

}
@media (max-width: 400px) {
  .pageIntroTitle {
    font-size: 30px;
  }
}
@media (max-width: 320px) {
  .smallFactsCompany {
    top: 450px;
  }
}