.inputstyle {
  background-color:#e8e8e8;
  border: none;
  border-radius: 30px;
  padding: 10px;
  width: 100%;
}

.inputstyle:focus, .inputstyle:active {
  background-color:#e8e8e8 !important;
  outline: 1px solid #bebebeb9 !important;
}

.smallDrowpdownArrow {
  background-size: 15px !important;
  background-position: calc(100% - 20px) 50%;
  color: #727272;
  font-size: medium;
}

.is-invalid {
  border: 2px solid red !important;
}

/* file upload */

.file-upload {
  display: flex;
  align-items: center;
  width: 100%;
}

.file-label {
  background-color: #e5e5e5;
  color: black;
  padding: 15px;
  font-family: sans-serif;
  border-radius: 10px 0 0 10px;
  flex: 1;
}

.file-chosen {
  padding: 15px 20px;
  background-color: #6c6c6c;
  color: white;
  border: none;
  border-radius: 0 10px 10px 0;
  width: 250px;
  font-family: sans-serif;
  text-align: center;
  cursor: pointer;
}

/* end file upload */

.blueStripeSeperator {
  background-color: #009fe3;
  height: 4px;
  width: 40px;
}

.submitButtonVacancieAdd {
  background-color: #009fe3;
  color: white;
  border: none;
  border-radius: 30px;
  width: 200px;
  padding: 10px;
}

.textarea-function {
  max-height: 200px;
}

.textarea-job {
  max-height: 500px;
}