.vacanciesParent {
  position: relative;
  bottom: 275px;
}

.vacancies {
  background-color: white;
  width: 85vw;
  height: 275px;
  padding: 50px 100px 0px 100px;
}


.imgVacatures {
  overflow-x: hidden;
  height: 600px;
  overflow-y: hidden;
  position: relative;
  text-align: center;
  color: white;
}

.imgVacatures > img {
  height: 100%; 
  width: 100%;
  object-fit: cover; 
  object-position: 75% 0%; 
}

.PageTitle {
  font-weight: 700;
  font-size: 80px;
  color: #2283c5;
}

.titlePageParent {
  justify-content: center;
}

.vacanciesList {
  padding: 0px 150px;
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 150px;
}

.vacanciesGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
  justify-items: center;
}

.footerVacancies {
  position: relative;
  bottom: 75px;
}

.cardContainer {
  display: flex;
  flex-direction: column;
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 20px 0px 0px 0px;
  margin-bottom: 0px !important;
  list-style: none;
}

.page-item {
  margin: 0 5px;
}

.page-link {
  cursor: pointer;
  padding: 8px 16px;
  background-color: #f2f2f2;
  color: #8f8f8f;
  text-decoration: none;
  border: none !important;
  border-radius: 4px;
}

.page-link:hover {
  filter: brightness(0.9);
  color: #8f8f8f;
}

.active-link {
  font-weight: bold;
  background-color: #2283c5 !important;
  color: white;
}

.active-link:hover {
  font-weight: bold;
  background-color: #2283c5;
  filter: brightness(1);
  cursor: default;
  color: white;
}

.filter-sectionVacancie {
  max-height: 170px;
  overflow-y: scroll;
}

.filterTitle {
  font-size: 20px;
  font-weight: 700;
}

.searchInputVacancies,
.searchInputVacancies:hover,
.searchInputVacancies:active,
.searchInputVacancies:focus {
  background-color: #f2f2f2 !important;
  border: none !important;
  padding: 15px !important;
}

.searchInputVacancies::placeholder {
  color: #8f8f8f;
  font-weight: 500;
}

.dividerSearchItems {
  border-bottom: 1px solid #2283c5;
  width: 100%;
  height: 1px;
}

.filterbutton {
  display: flex;
  justify-content: center;
  position: sticky;
  bottom: 30px;
  z-index: 10;
}

.filterButtonStyle {
  background-color: #2283c5;
  border: none;
  box-shadow: 1px 2px 2px 0px rgba(0,0,0,1);
  color: white;
  border-radius: 5px;
  width: 200px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.filterButtonStyleResult{
  background-color: #2283c5;
  border: none;
  box-shadow: 1px 2px 2px 0px rgba(0,0,0,1);
  color: white;
  border-radius: 5px;
  width: 175px;
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/* Burger Menu Styles */
.burgerMenu {
  position: fixed;
  top: 0;
  left: -100%;
  width: 80%;
  max-width: 300px;
  height: 100%;
  background: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  padding: 40px 20px;
  overflow-y: auto;
  z-index: 1000;
  transition: left 0.3s ease-in-out;
}

.burgerMenu.open {
  left: 0;
}

.burgerMenu .closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}


/* Custom Checkbox Styling */
.custom-checkbox .custom-check-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  display: none;
}

.custom-check-label {
  color: #8f8f8f;
}

.custom-checkbox .custom-check-input + .custom-check-label::before {
  content: "";
  display: inline-block;
  min-width: 25px;
  height: 25px;
  cursor: pointer;
  margin-right: 10px;
  border: 2px solid #f2f2f2;
  background-color: #f2f2f2;
  border-radius: 4px;
  transition: background-color 0.2s, border-color 0.2s;
}

.custom-checkbox .custom-check-input:checked + .custom-check-label::before {
  background-color: #2283c5;
  border-color: #2283c5;
}

.custom-checkbox .custom-check-input + .custom-check-label::after {
  content: "";
  position: absolute;
  left: 4px;
  top: 1px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.2s;
}

.custom-checkbox .custom-check-input:checked + .custom-check-label::after {
  opacity: 1;
}

/* loading */

.loader {
  width: fit-content;
  font-weight: bold;
  font-family: monospace;
  font-size: 30px;
  clip-path: inset(0 3ch 0 0);
  animation: l4 1s steps(4) infinite;
}
.loader:before {
  content:"Laden..."
}
@keyframes l4 {to{clip-path: inset(0 -1ch 0 0)}}

/* loading */

/* end checkbox styling */
@media (min-width: 2400px) {
  .vacanciesGrid {
    gap: 0px !important;
  }
}

@media (min-width: 1921px) {
  .vacanciesParent {
    position: relative;
    bottom: 250px !important;
  }
}

@media (max-width: 1755px) {
  .vacanciesList {
    padding: 0px 30px;
  }

  .imgVacatures {
    height: 600px;
    overflow-y: hidden;
  }

  .imgVacatures > img {
    height: 100%;
    width: 100%;
  }
}

@media (max-width: 1515px) {
  .vacanciesGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 1030px) {
  .vacanciesList {
    flex-direction: column !important;
  }

  .footerVacancies {
    position: relative;
    bottom: 0px !important;
  }

  .filterbutton {
    position: relative;
    bottom: 75px !important;
  }

  .searchFilters {
    width: 100% !important;
    padding-right: 0px !important;
  }

  .jobOffersGridParent {
    width: 100% !important;
  }
}


@media (max-width: 790px) {
  .vacanciesGrid {
    grid-template-columns: repeat(1, 1fr);
  }

  .PageTitle {
    font-size: 50px;
  }

  .titlePageParent {
    justify-content: center;
  }

  .subtitleVacancie {
    font-size: 15px !important;
  }
  .filterbutton {
    bottom: 50px;
  }
  .imgVacatures {
    height: 300px;
  }
  .vacanciesList {
    bottom: 150px;
  }

  .vacancies {
    height: 300px !important;
  }

  .vacanciesParent {
    bottom: 180px !important;
  }

  
}


@media (max-width: 440px) {
  .PageTitle {
    font-size:35px;
  }

  .IconButtonStyle {
    padding: 1px;
  }

  .burgerMenu.open {
    max-width: 100%;
    width: 100%;
  }
  .subtitleVacancie {
    font-size: 15px !important;
  }

  .vacancies {
    height: 200px !important;
  }
  .vacanciesList {
    bottom: 120px;
}
}