/* @import url(https://fonts.googleapis.com/css?family=Montserrat); */

label, h1, h2, h3, h4, h5, h6, a, p, span {
  font-family: "proxima-nova", sans-serif;
}

.proxima-nova-regular {
  font-family: "proxima-nova", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.proxima-nova-semibold {
  font-family: "proxima-nova", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.proxima-nova-bold {
  font-family: "proxima-nova", sans-serif;
  font-weight: 700;
  font-style: normal;
}


/* scroll bar Styling */

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(219, 219, 219); 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #2283c5; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #176192; 
}

/* end scroll bar Styling */


/* default styles */

.cursorPointer {
  cursor: pointer;
}

.iconButtonArrowRight > button > div {
  padding: 10px 6px;
}

.iconButtonArrowRight > button > div > img {
  width: 20px;
}

.standardDropdownArrow {
  appearance: none;
  /* Removes default browser styling */
  -webkit-appearance: none;
  /* Specifically for WebKit browsers */
  background-image: url('../Images/chevron-down.svg');
  /* Your custom arrow image */
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) 50%;
  /* Moves the arrow right */
  background-size: 20px;

  font-size: large;
  font-weight: 600;
}

.char-counter {
  font-size: 0.875rem;
  color: #6c757d;
}



/* end default styles */