.backgroundGradient {
  background: linear-gradient(90deg, rgba(5,134,211,1) 0%, rgba(12,18,23,1) 100%);
  height: 100vh;
}

.inputsUserCredentials {
  border-radius: 20px !important;
  width: 700px;
  height: 40px;
  padding: 0px 15px !important;
}

.logoLogin {
  width: 500px; 
  height: auto;
  position: relative;
  bottom: 30px;
  filter: brightness(0) invert(1);
}

.input-containerLogin {
  position: relative;
  width: 700px; /* Ensure the container matches the width of other inputs */
}

.input-containerLogin input {
  border-radius: 20px !important;
  width: 100%; /* Ensure input takes full width of the container */
  height: 40px;
  padding: 0px 15px !important;
  padding-right: 40px; /* Add padding to make space for the icon */
}

.toggle-visibilityLogin {
  position: absolute;
  right: 10px; /* Position the icon inside the padding */
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.loginButton {
  background-color: #009FE3;
  height: 40px;
  color: white;
}

.loginButton:hover, .loginButton:focus, .loginButton:active {
  background-color: #0090ce !important;
  color: white !important;
  outline: none;
  border: #00000000;
}

.forgotPassword {
  color: #8abccf;
  cursor: pointer;
  width: fit-content;
  text-decoration: underline;
}
