.inputstyle {
  background-color:#e8e8e8;
  border: none;
  border-radius: 30px;
  padding: 10px;
  width: 100%;
}

.inputstyle:focus, .inputstyle:active {
  background-color:#e8e8e8 !important;
  outline: 1px solid #bebebeb9 !important;
}

.settingsButtons {
  background-color: #009fe3;
  color: white;
  border: none;
  width: 250px;
  padding: 10px;
  border-radius: 30px;
  font-size: 15px;
  text-decoration: none;
  text-align: center;
}

.formSettings {
  border-bottom: 2px solid #cecece;
  padding-bottom: 60px;
}

.smallLabels {
  background-color: #e8e8e8;
  width: fit-content;
  display: flex;
  flex-direction: row;
  padding: 3px 5px;
  align-items: center;
  border-radius: 4px;
}

.input-container {
  position: relative;
}

.input-container input {
  width: calc(100% - 30px); /* Adjust width to accommodate the icon */
}

.toggle-visibility {
  position: absolute;
  right: 20px;
  user-select: none;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.buttonSmallLabels {
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
}