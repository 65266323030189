.bodyContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75vh;
}

.message {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    flex-direction: row;
}

.text > h1 {
    color: #1C71B9;
    font-weight: bold;
    font-size: 85px;
}

.text > p {
    font-size: 20px;
    margin-top: 20px;
}

@media (max-width: 1500px) {
    .message {
        width: 80%;
    }

    .text > h1 {
        font-size: 70px;
    }
}

@media (max-width: 1220px) {
    .message {
        width: 80%;
    }

    .text > h1 {
        font-size: 60px;
    }
}

@media (max-width: 1100px) {
    .message {
        width: 80%;
    }
    .text > h1 {
        font-size: 50px;
    }
    .img > img{
        height: 450px;
    }
}

@media (max-width: 980px) {
    .message {
        width: 100%;
        margin: 0px;
        padding: 0px 50px;
    }

    .text > h1 {
        font-size: 45px;
    }
    .img > img{
        height: 450px;
    }
}

@media (max-width: 860px) {
    .message {
        padding: 0px 30px;
    }

    .text > h1 {
        font-size: 40px;
    }
    .img > img{
        height: 400px;
    }
}
@media (max-width: 750px) {
    .message {
        flex-direction: column;
        padding: 0px 30px;
    }

    .text {
        margin-top: 10px;
    }
}

@media (max-width: 600px) {
    .message {
        padding: 0px 30px;
    }

    .text > h1 {
        font-size: 35px;
    }
    .img > img{
        height: 350px;
    }
}

@media (max-width: 480px) {
    .message {
        padding: 0px 10px;
    }

    .text > h1 {
        font-size: 30px;
    }
    .img > img{
        height: 300px;
    }
}

@media (max-width: 350px) {
    .message {
        padding: 0px 10px;
    }

    .text > h1 {
        font-size: 30px;
    }
    
    .img > img{
        height: 250px;
    }
}