.PageLayout {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ParentNavigationbar {
  width: 50%;
}

.logoBackoffice {
  width: 500px;
}

.BackofficeButtonsNavigation {
  background-color: #009fe3;
  color: white;
  border: none;
  width: 250px;
  padding: 10px;
  border-radius: 30px;
  font-size: 15px;
  text-decoration: none;
  text-align: center;
}


.BackofficeButtonsNavigation.active {
  background-color: #05628a !important;
  color: white !important;
}

