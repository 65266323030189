/* end iconButton Styling */

.IconButtonStyle {
  background-color: #2283c5;
  padding: 6px;
  border: none;
  border-radius: 50px;
  display: flex;
  align-items: center;
  max-width: fit-content;
  text-decoration: none;
  min-width: fit-content;
}

.IconButtonStyle:disabled {
  cursor: default;
}

.iconbackground {
  background-color: #2283c5;
  padding: 10px;
  border-radius: 100%;
  transition: background-color 0.5s ease;
}

.IconButtonStyle:hover .iconbackground {
  background-color: #176192;
}

.IconButtonStyleReverted {
  background-color: #176192;
  padding: 6px;
  border: none;
  border-radius: 50px;
  display: flex;
  align-items: center;
}

.IconButtonStyleReverted:disabled {
  cursor: default;
}

.iconbackgroundReverted {
  background-color: #176192;
  padding: 10px;
  border-radius: 100%;
  transition: background-color 0.5s ease;
}

.IconButtonStyleReverted:hover .iconbackgroundReverted {
  background-color: #2283c5;
}

/* end iconButton Styling */

/* vacanciesCard Styling */

.cardContainer {
  width: 350px; /* Je kunt deze breedte aanpassen als je wilt dat de hele container 300px breed is */
  margin-bottom: 20px; /* Optional: Add some margin at the bottom for spacing between cards */
  position: relative;
  height: 560px;
}

.imgWrapper {
  width: 350px;
  height: 250px;
  overflow: hidden; /* Verbergt delen van de afbeelding die buiten het kader vallen */
}

.imgCard {
  width: 100%;
  height: 100%;
  object-fit:cover; /* Zorgt ervoor dat de afbeelding proportioneel blijft en de hele container vult */
}

.LabelInfo {
  height: 110px;
  width: 85%;
  position: absolute;
  top: 195px; /* Adjust based on the height of the image to position the label */
  z-index: 2;
  left: 0;
  right: 0;
}

.funcTitle {
  background-color: #2283c5;
  color: white;
  border-top-right-radius: 100px;
  display: flex;
  align-items: center;
  font-size: larger; 
  font-weight: 600;
}

.funcInfo {
  background-color: #176192;
  color: white;
  border-bottom-right-radius: 100px;
}

.jobDescription {
  background-color: #f9f9f9;
  padding: 20px 15px; /* Adjust the padding as needed */
  margin-top: 0; /* Removed negative margin */
  padding-top: 75px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.jobDescription > p {
  min-height: 110px;
  max-height: 110px;
  max-width: 315px;
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
}

.grayBackground {
  background-color: #f9f9f9;
  position: absolute;
  top: 200px; /* Align with the top of LabelInfo */
  width: 100%;
  height: calc(100% - 200px); /* Ensure it covers from the bottom of the image to the bottom of the card */
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 1;
}

.functiondescription {
  word-break: break-all;
}

/* end vacanciesCard Styling */

@media (max-width: 410px) {
  .cardContainer {
    width: 260px;
  }

  .imgWrapper {
    width: 100%;
    height: 250px;
  }

  .funcTitle {
    overflow: hidden;
  }
}
