.VacancieCardBackoffice {
  background-color: #e8e8e8;
  padding: 15px;
  border-radius: 10px;
  width: fit-content;
  max-width: 280px;
}

.imgvacanciesCard {
  width: 250px;
  height: 250px;
  border-radius: 10px;
  object-fit: cover;
  object-position: top;
  overflow: hidden;
  position: relative;
}

.vacancies {
  background-color: white;
  width: 85vw;
  height: 300px;
  padding: 50px 100px 0px 100px;
}

.allVacanciesList {
  padding: 0px 0px;
  display: flex;
  justify-content: center;
  position: relative;
  bottom: 0px;
}

.vacanciesGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;
  justify-items: center;
}

.functionDescription {
  min-height: 50px;
  max-height: 50px;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  width: auto !important;
}

.pagination {
  display: flex;
  justify-content: center;
  padding: 20px 0px 0px 0px;
  margin-bottom: 0px !important;
  list-style: none;
}

.page-item {
  margin: 0 5px;
}

.page-link {
  cursor: pointer;
  padding: 8px 16px;
  background-color: #f2f2f2;
  color: #8f8f8f;
  text-decoration: none;
  border: none !important;
  border-radius: 4px;
}

.page-link:hover {
  filter: brightness(0.9);
  color: #8f8f8f;
}

.active-link {
  font-weight: bold;
  background-color: #2283c5 !important;
  color: white;
}

.active-link:hover {
  font-weight: bold;
  background-color: #2283c5;
  filter: brightness(1);
  cursor: default;
  color: white;
}

.whiteBackground {
  background-color: white;
  padding: 0px 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  color: #8f8f8f;
  font-size: small;
}

.DeleteButtonVacancie {
  background-color: #2283c5;
  border: none; 
  color: white;
  width: 100%;
  border-radius: 30px;
  padding: 10px 0px;
}

/* switch button */

.StatusButtonVacancie {
  background-color: #4CAF50; /* Green for active */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  border-radius: 30px;
  display: inline-block;
  width: 100%;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.StatusButtonVacancie.inactive {
  background-color: #f44336; /* Red for inactive */
}

/* switch button */

@media (max-width: 1515px) {
  .vacanciesGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 1030px) {
  .vacanciesList {
    flex-direction: column !important;
  }

  .jobOffersGridParent {
    width: 100% !important;
  }
}

@media (max-width: 790px) {
  .vacanciesGrid {
    grid-template-columns: repeat(1, 1fr);
  }

  .PageTitle {
    font-size: 50px;
  }

  .titlePageParent {
    justify-content: center;
  }

  .subtitleVacancie {
    font-size: 15px !important;
  }
}

@media (max-width: 440px) {
  .PageTitle {
    font-size:35px;
  }

  .subtitleVacancie {
    font-size: 15px !important;
  }
}