.footer {
    background-color: #1C71B9;
    color: white;
    font-size: 18px;
}

.footer-container {
    width: 60%;
    margin: 0 auto;
    padding: 60px 0px;
    justify-content: space-around;
}

.header {
    font-size: 25px;
    font-weight: bold;
}


.products,
.about,
.socials,
.techdog {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-left: 6%;
}

.logo {
    width: 350px;
    height: auto;
    margin-bottom: 20px;
}

.icon {
    display: flex;
    align-items: center;
    margin: 10px;
}

.icon>img {
    margin-right: 8px;
    width: 18px;
    height: auto;
}

.pin {
    width: 20px !important;
}

.fb {
    width: 10px !important;
}

.officeHours {
    margin-left: 25px;
}

.footerBottom {
    background-color: #07649D;
    height: 55px;
}

.bottomContent {
    width: 60%;
}

.footerBottom a {
    color: inherit;
    text-decoration: none;
    margin: 0 5px;
}

.footerBottom p {
    margin: 0;
    font-size: 20px;
}

@media (max-width: 1500px) {
    .footer-container {
        width: 80%;
    }

    .bottomContent {
        width: 65%;
        ;
    }
}

@media (max-width: 1220px) {
    .footer-container {
        width: 95%;
    }

    .bottomContent {
        width: 70%;
        padding-left: 5%;
    }

}

@media (max-width: 1100px) {

    .bottomContent {
        width: 80%;
        padding-left: 5%;
    }

}

@media (max-width: 990px) {

    .products,
    .about,
    .socials,
    .techdog {
        padding-left: 15%;
    }

    .bottomContent {
        width: 90%;
        padding-left: 3%;
    }
}

@media (max-width: 770px) {

    .products,
    .about,
    .socials,
    .techdog {
        align-items: center;
        padding-left: 5%;
    }

    .footerBottom p {
        font-size: 15px;
    }

    .header {
        font-size: 20px;
    }
    p,
    a {
        font-size: 15px;
    }

}

@media (max-width: 550px) {
    .footerBottom {
        height: 75px;
    }

    .bottomContent {
        width: 100%;
    }

    .footerBottom p {
        margin: 0px 15px
    }
}

@media (max-width: 380px) {
    .footerBottom {
        height: 100px;
    }

    .footerBottom p {
        margin: 0px 10px
    }
}