.navbarStyling {
  background-color: rgb(255, 255, 255);
  height: 125px;
  padding: 0px 8rem;
}

.LogoStyle {
  height: 50px;
}

.dropdown-menu-custom {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 1rem 0;
}

.dropdown-menu-custom .navbar-nav {
  flex-direction: column;
  align-items: center;
}

.dropdown-menu-custom .nav-item {
  width: 100%;
  text-align: center;
  padding: 0.5rem 0;
}

.navItems {
  color: black;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.2;
}

.inActive {
  cursor: default;
}


@media (max-width: 1260px) {
  .navbarStyling {
    padding: 1rem 0.50rem; /* Reduced padding for medium screens */
  }

  .LogoStyle {
    height: 40px;
  }

  .navbar-nav {
    width: 100% !important;
    margin-right: 0PX !important;
  }
}

@media (max-width: 420px) {
  .LogoStyle {
    height: 30px !important;
  }
}

@media (max-width: 345px) {
  .LogoStyle {
    height: 25px !important;
  }
}
